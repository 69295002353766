import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../providers/ApiProvider";
import { ERROR_MESSAGES, FansUnitedFeatures } from "../../../enums/enums";
import { ReportParamsContext } from "../../../providers/ReportParamsProvider";
import { setDefaultCoparedToDateApi } from "../../../helpers/helpers";
import ReportPeriods from "../../../models/Reports/ReportPeriods";
import PotmUsersVotesBreakdown from "../../../models/Reports/PoTM/PotmUsersVotesBreakdown";
import { CompetitionBasicModel, CompetitionFilters } from "fansunited-sdk-esm";

export const usePotmBreakdown = (competitionId: string) => {
  const [potmBreakdown, setPotmBreakdown] =
    useState<ReportPeriods<PotmUsersVotesBreakdown>>(null);
  const [fullCoverageCompetitions, setFullCoverageCompetitions] = useState<
    CompetitionBasicModel[]
  >([]);

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);
  const { datePeriod, comparedToPeriod, comparedTo, groupBy } =
    useContext(ReportParamsContext);

  const getPotmBreakdown = async () => {
    try {
      const potmParticipation = await api.reportingHttps.getPotmBreakdown(
        datePeriod,
        groupBy,
        competitionId
      );
      let comparedToBreakdown: any = null;

      if (comparedTo) {
        comparedToBreakdown = await api.reportingHttps.getPotmBreakdown(
          comparedToPeriod ? comparedToPeriod : setDefaultCoparedToDateApi(),
          groupBy,
          competitionId
        );
      }

      setPotmBreakdown({
        current: potmParticipation,
        comparedTo: comparedToBreakdown,
      });
    } catch (error) {
      toast.error(ERROR_MESSAGES.POTM_BREAKDOWN_REPORT);
      setPotmBreakdown({
        current: new PotmUsersVotesBreakdown(),
        comparedTo: new PotmUsersVotesBreakdown(),
      });
    }
  };

  const getFullCoverageCompetitions = async () => {
    try {
      const predictorFeatures = await api.clientHttps.getClientSpecificFeature(
        FansUnitedFeatures.PREDICTOR
      );
      const competitions = await api.sdk.football.getCompetitions({
        competitionIds: predictorFeatures.fullCoverageCompetitions,
      } as CompetitionFilters);
      // Extract numeric ID from competition ID string (e.g. "fb:c:1" -> 1)
      const getNumericId = (id: string) => {
        return parseInt(id.split(":")[2]);
      };

      const sortedCompetitions = competitions.sort(
        (a, b) => getNumericId(a.id) - getNumericId(b.id)
      );
      setFullCoverageCompetitions(sortedCompetitions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPotmBreakdown();

    return () => setPotmBreakdown(null);
  }, [
    selectedClient,
    comparedToPeriod,
    groupBy,
    datePeriod,
    comparedTo,
    competitionId,
  ]);

  useEffect(() => {
    getFullCoverageCompetitions();

    return () => setFullCoverageCompetitions([]);
  }, []);

  return { potmBreakdown, fullCoverageCompetitions };
};
