import {
  previousPeriodDatasetLabel,
  selectedPeriodDatasetLabel,
} from "../../constants/constants";
import { removePropertyFromObject, upperCase } from "../../helpers/helpers";
import DatasetChart from "../../models/Reports/DatasetChart";
import { IMiniGamesBreakdown } from "../../models/Reports/MiniGames/MiniGamesParticipations";
import UsersBreakdown from "../../models/Reports/UsersBreakdown";

export default class UsersParticipationsBreakdownBuilder {
  private newDatasetsChart: DatasetChart[] = [];
  readonly data: any;
  readonly usersBreakdown: string[];

  constructor(data: any, usersBreakdown: string[]) {
    this.data = data;
    this.usersBreakdown = usersBreakdown;
  }

  public build = () => {
    this.buildMiniGamesDatasetsChart();

    return this.newDatasetsChart;
  };

  private buildMiniGamesDatasetsChart = () => {
    let newComparedToParticipationsBreakdown: any = null;

    const newCurrentParticipationsBreakdown = this.data?.current?.breakdown.map(
      (value: IMiniGamesBreakdown) => {
        const keysToBeRemoved = Object.keys(value.participations).filter(
          (userKey: string) => !this.usersBreakdown.includes(userKey)
        );

        keysToBeRemoved.forEach((key: string) =>
          removePropertyFromObject(value.participations, key)
        );

        return value.participations;
      }
    );

    this.usersBreakdown.forEach((userBreakdown: string) => {
      const newDatasetChart = new DatasetChart();

      newDatasetChart.id = `${userBreakdown}_selected_period`;
      newDatasetChart.label = `${upperCase(
        userBreakdown
      )}${selectedPeriodDatasetLabel}`;
      newDatasetChart.backgroundColor =
        this.selectedPeriodColorDataset(userBreakdown);
      newDatasetChart.borderColor =
        this.selectedPeriodColorDataset(userBreakdown);

      this.newDatasetsChart.push(newDatasetChart);
    });

    if (this.data && this.data.comparedTo) {
      // Create new user breakdown depending on the checkboxes the client has selected.
      newComparedToParticipationsBreakdown = this.data.comparedTo.breakdown.map(
        (value: IMiniGamesBreakdown) => {
          const keysToBeRemoved = Object.keys(value.participations).filter(
            (userKey: string) => !this.usersBreakdown.includes(userKey)
          );

          keysToBeRemoved.forEach((key: string) =>
            removePropertyFromObject(value.participations, key)
          );

          return value.participations;
        }
      );

      this.usersBreakdown.forEach((userBreakdown: string) => {
        const newDatasetChart = new DatasetChart();

        newDatasetChart.id = `${userBreakdown}_previous_period`;
        newDatasetChart.label = `${upperCase(
          userBreakdown
        )}${previousPeriodDatasetLabel}`;
        newDatasetChart.backgroundColor =
          this.previousPeriodColorDataset(userBreakdown);
        newDatasetChart.borderColor =
          this.previousPeriodColorDataset(userBreakdown);

        this.newDatasetsChart.push(newDatasetChart);
      });
    }

    if (newCurrentParticipationsBreakdown) {
      Object.values(newCurrentParticipationsBreakdown).forEach(
        (value: UsersBreakdown) => {
          this.newDatasetsChart.forEach((datasetChart: DatasetChart) => {
            if (datasetChart.id === `all_selected_period`)
              datasetChart.data.push(value.all);
            else if (datasetChart.id === `male_selected_period`)
              datasetChart.data.push(value.male);
            else if (datasetChart.id === `female_selected_period`)
              datasetChart.data.push(value.female);
            else if (datasetChart.id === `unspecified_selected_period`)
              datasetChart.data.push(value.unspecified);
          });
        }
      );
    }

    if (newComparedToParticipationsBreakdown) {
      Object.values(newComparedToParticipationsBreakdown).forEach(
        (value: UsersBreakdown) => {
          this.newDatasetsChart.forEach((datasetChart: DatasetChart) => {
            if (datasetChart.id === `all_previous_period`)
              datasetChart.data.push(value.all);
            else if (datasetChart.id === `male_previous_period`)
              datasetChart.data.push(value.male);
            else if (datasetChart.id === `female_previous_period`)
              datasetChart.data.push(value.female);
            else if (datasetChart.id === `unspecified_previous_period`)
              datasetChart.data.push(value.unspecified);
          });
        }
      );
    }
  };

  private selectedPeriodColorDataset = (userBreakdown: string) => {
    switch (userBreakdown) {
      case "all":
        return "rgba(0, 102, 204)";
      case "male":
        return "rgba(4, 133, 81)";
      case "female":
        return "rgba(194, 4, 4)";
      case "unspecified":
        return "rgba(163, 166, 3)";
    }
  };

  private previousPeriodColorDataset = (userBreakdown: string) => {
    switch (userBreakdown) {
      case "all":
        return "#ed7e17";
      case "male":
        return "#b7ffab";
      case "female":
        return "#fa7575";
      case "unspecified":
        return "#e6e872";
    }
  };
}
