import {
  MainPagesEnum,
  ManagementWidgetsPagesEnum,
  PermissionsEnum,
} from "../enums/enums";
import RolesPermissions from "../models/Clients/RolesPermissions";

export default class LoggedInUserService {
  private userLoggedInPermissions: string[] = [];

  constructor(clientId: string, roles: RolesPermissions, loggedInUser: any) {
    const userLoggedInRoles = this.getUserLoggedInRole(clientId, loggedInUser);
    this.constructUserLoggedInPermissions(roles, userLoggedInRoles);
  }

  public pagesUserHasAccessTo = () => {
    let pages: string[] = [];

    pages.push(MainPagesEnum.DASHBOARD);
    pages.push(MainPagesEnum.API_KEYS);
    pages.push(MainPagesEnum.EDIT_PROFILE);
    pages.push(MainPagesEnum.CHANGE_PASSWORD);

    if (
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_LOYALTY) ||
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_MATCH_QUIZZES) ||
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_PREDICTOR) ||
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_TOP_X) ||
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_EXTERNAL_POINTS) ||
      this.userHasAccess(PermissionsEnum.MANAGE_FEATURE_WEBHOOK)
    )
      pages.push(MainPagesEnum.FEATURES);

    if (this.userHasAccess(PermissionsEnum.MANAGE_STAFF))
      pages.push(MainPagesEnum.STAFF);

    if (this.userHasAccess(PermissionsEnum.MANAGE_CONFIG))
      pages.push(MainPagesEnum.CONFIGURATION);

    if (this.userHasAccess(PermissionsEnum.VIEW_REPORTS))
      pages.push(MainPagesEnum.REPORTS);
    if (this.userHasAccess(PermissionsEnum.MANAGE_TOP_X))
      pages.push(ManagementWidgetsPagesEnum.TOP_X_GAMES);

    if (this.userHasAccess(PermissionsEnum.MANAGE_MATCH_QUIZZES))
      pages.push(ManagementWidgetsPagesEnum.MATCH_QUIZ_GAMES);

    if (this.userHasAccess(PermissionsEnum.MANAGE_LEADERBOARD_TEMPLATES)) {
      pages.push(ManagementWidgetsPagesEnum.COMPETITION_GAME);
      pages.push(ManagementWidgetsPagesEnum.TEAMS_GAME);
      pages.push(ManagementWidgetsPagesEnum.MATCHES_GAME);
      pages.push(ManagementWidgetsPagesEnum.FANTASY_GAME);
      pages.push(ManagementWidgetsPagesEnum.CUSTOM_GAME);
    }

    if (this.userHasAccess(PermissionsEnum.MANAGE_MINI_GAMES)) {
      pages.push(ManagementWidgetsPagesEnum.CLASSIC_QUIZZES);
      pages.push(ManagementWidgetsPagesEnum.EITHER_OR);
    }

    if (this.userHasAccess(PermissionsEnum.MANAGE_DISCUSSIONS))
      pages.push(ManagementWidgetsPagesEnum.DISCUSSIONS);

    if (this.userHasAccess(PermissionsEnum.MANAGE_POLLS))
      pages.push(ManagementWidgetsPagesEnum.POLL);

    if (this.userHasAccess(PermissionsEnum.MANAGE_LISTS))
      pages.push(ManagementWidgetsPagesEnum.LIST);

    if (this.userHasAccess(PermissionsEnum.MANAGE_CUSTOM_GAMES)) {
      pages.push(ManagementWidgetsPagesEnum.BRACKET);
      pages.push(ManagementWidgetsPagesEnum.STANDING);
    }

    if (this.userHasAccess(PermissionsEnum.MANAGE_LEADS))
      pages.push(ManagementWidgetsPagesEnum.LEAD);

    if (this.userHasAccess(PermissionsEnum.MANAGE_VERIFIED_AND_STAFF_MEMBERS))
      pages.push(ManagementWidgetsPagesEnum.PROFILE);

    return pages;
  };

  private userHasAccess = (permission: string) => {
    return this.userLoggedInPermissions.includes(permission);
  };

  /**
   * Get user global role if he has any or his role for the current selected client
   * @param clientId Selected client in Management portal
   * @param loggedInUser Decoded token for the currently logged in user
   * @returns Client's role
   */
  private getUserLoggedInRole = (
    clientId: string,
    loggedInUser: any
  ): string[] => {
    if (loggedInUser.roles.global && loggedInUser.roles.global.length)
      return loggedInUser.roles.global;

    return loggedInUser.roles[clientId];
  };

  private constructUserLoggedInPermissions = (
    roles: RolesPermissions,
    userLoggedInRoles: string[]
  ) => {
    const permissions: any[] = [];

    userLoggedInRoles.forEach((role: string) => {
      permissions.push(roles[role as keyof typeof roles]);
    });

    const flattedPermissions = permissions.flat();
    const permissionsSet = new Set(flattedPermissions);
    this.userLoggedInPermissions = Array.from(permissionsSet);
  };
}
