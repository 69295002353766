import React, { useState } from "react";
import { usersBreakdownDefault } from "../../../../../../constants/constants";
import { useEitherOrBreakdown } from "../../../../../../hooks/reports/miniGames/useEitherOrBreakdown";
import UsersParticipationsBreakdownBuilder from "../../../../../../builders/reports/UsersParticipationsBreakdownBuilder";
import CardReport from "../../../../../Cards/CardReport";
import ReportInfo from "../../../Common/ReportInfo";
import DatePeriod from "../../../Common/DatePeriod";
import GroupByPeriod from "../../../Common/GroupByPeriod";
import TotalUsers from "../../../Common/TotalUsers/TotalUsers";
import LineChart from "../../../Common/LineChart";
import { constructLabelsChart } from "../../../../../../helpers/helpers";
import Spinner from "../../../../../Spinner/Spinner";

const EitherOrBreakdown: React.FC = () => {
  const [usersBreakdown, setUsersBreakdown] = useState<string[]>(
    usersBreakdownDefault
  );

  const onChangeCheckbox = (userBreakdown: string) => {
    const currentUsersBreakdown = [...usersBreakdown];

    if (
      currentUsersBreakdown.includes(userBreakdown) &&
      currentUsersBreakdown.length !== 1
    ) {
      const newUsersBreakdown = currentUsersBreakdown.filter(
        (value: string) => value !== userBreakdown
      );
      setUsersBreakdown(newUsersBreakdown);
    } else if (!currentUsersBreakdown.includes(userBreakdown)) {
      currentUsersBreakdown.push(userBreakdown);
      setUsersBreakdown(currentUsersBreakdown);
    }
  };

  const eitherOrBreakdown = useEitherOrBreakdown();
  const datasets = new UsersParticipationsBreakdownBuilder(
    JSON.parse(JSON.stringify(eitherOrBreakdown)),
    usersBreakdown
  ).build();

  return (
    <CardReport>
      <div className="headingContainer mb-6">
        <ReportInfo
          title={"Either/Or breakdown"}
          description={
            "This is a breakdown of your users participations in Either/Or mini games for a specified period of time. In this report you will also find a breakdown by gender."
          }
        />
        <div className="xl:flex xl:flex-row xl:items-center">
          <DatePeriod />
        </div>
      </div>
      <GroupByPeriod />
      {eitherOrBreakdown && eitherOrBreakdown.current ? (
        <>
          <TotalUsers
            total={eitherOrBreakdown.current.total}
            reportType={"users_acquired"}
            usersBreakdown={usersBreakdown}
            onChangeCheckbox={onChangeCheckbox}
          />
          <LineChart
            labels={constructLabelsChart(
              eitherOrBreakdown.current.breakdown,
              eitherOrBreakdown?.comparedTo?.breakdown
            )}
            datasets={datasets}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default EitherOrBreakdown;
