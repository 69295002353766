import React from "react";
import { useDiscussionsAverages } from "../../../../../hooks/reports/discussions/useDiscussionsAverages";
import Spinner from "../../../../Spinner/Spinner";
import CardReport from "../../../../Cards/CardReport";
import ReportInfo from "../../Common/ReportInfo";
import DiscussionsAverageStats from "./DiscussionsAverageStats";
import Stats from "../../../Dashboard/Stats/Stats";

const DiscussionsAverages: React.FC = () => {
  const discussionsAverages = useDiscussionsAverages();

  return (
    <CardReport>
      <div className="mb-6">
        <ReportInfo
          title={"Discussions averages"}
          description={
            "This report shows the average number of posts for all public discussions, total posts and number of users with at least X posts in public discussions."
          }
        />
      </div>
      {discussionsAverages ? (
        <>
          <DiscussionsAverageStats
            type={"average_posts"}
            data={discussionsAverages.averagePosts}
          />
          <div className="mb-6">
            <div className="mb-4">
              <h2 className="font-bold mb-2">Total number of posts</h2>
              <hr className="mb-2"></hr>
            </div>
            <div className="lg:grid lg:grid-cols-3 lg:gap-2 mb-4">
              <div className="w-full p-4 min-w-0 break-words bg-blueGray-50 rounded shadow-lg mb-4">
                <Stats
                  statsName={"Total number of posts"}
                  label={"The total number of all posts generated"}
                  value={
                    discussionsAverages.averagePosts.averageNumberPerUser || 0
                  }
                  icon={""}
                  iconColor={""}
                />
              </div>
            </div>
          </div>
          <DiscussionsAverageStats
            type={"percentage_users_posts"}
            data={discussionsAverages.percentageUsersWithPosts}
          />
        </>
      ) : (
        <Spinner />
      )}
    </CardReport>
  );
};

export default DiscussionsAverages;
