import { FansUnitedFeatures } from "../../enums/enums";
import { remapKeysToSpecificCase } from "../../helpers/helpers";
import CacheConfigModel from "../../models/Clients/CacheConfigModel";
import ClientFullInfo from "../../models/Clients/ClientFullInfo/ClientFullInfo";
import { InfrastructureType } from "../../models/Clients/ClientsBasicInfo";
import EngagementCoefficientsConfiguration from "../../models/Clients/EngagementCoefficientsConfiguration/EngagementCoefficientsConfiguration";
import FootballSettingsConfigModel from "../../models/Clients/FootballSettingsConfigModel";
import FullProfileInfo from "../../models/Clients/FullProfileInfo";
import ProfileInfo from "../../models/Clients/ProfileInfo";
import RolesPermissions from "../../models/Clients/RolesPermissions";
import SignedInUrlRequestBody from "../../models/Clients/SignedInUrl/SignedInUrlRequestBody";
import StaffMemberRequestBody from "../../models/Clients/StaffMemberRequestBody";
import Https from "./Https";

const initBaseURL = (infrastructure: InfrastructureType) => {
  switch (infrastructure) {
    case "DEV":
      return "http://localhost:8070/v1";
    case "LOCAL":
      return "http://localhost:8070/v1";
    case "STAGE":
      return "https://client.fansunitedstagingapi.com/v1";
    case "PROD":
      return "https://client.fansunitedapi.com/v1";
    case "PROD_WATG":
      return "https://client.watg.fansunitedapi.com/v1";
    case "PROD_YOLO":
      return "https://client.yolo.fansunitedapi.com/v1";
  }
};

type ClientTypeEndpoints =
  | "staff_clients"
  | "client_info"
  | "roles"
  | "staff_info"
  | "staff_members_client"
  | "specific_feature"
  | "client_features"
  | "client_config_cache_ttl"
  | "update_external_points_config"
  | "engagement_coefficients_config"
  | "client_config_football_settings"
  | "client_signed_in_url"
  | "badges";

const constructPathParams = (
  typeEndpoint: ClientTypeEndpoints,
  userId?: string,
  clientId?: string,
  typeFeature?: FansUnitedFeatures
) => {
  switch (typeEndpoint) {
    case "staff_clients":
      return `/staff/${userId}/clients`;
    case "roles":
      return `/roles`;
    case "client_info":
      return `/clients/${clientId}`;
    case "staff_info":
      return `/clients/${clientId}/staff/${userId}`;
    case "staff_members_client":
      return `/clients/${clientId}/staff`;
    case "specific_feature":
      return `/clients/${clientId}/features/${typeFeature}`;
    case "client_features":
      return `/clients/${clientId}/features`;
    case "client_config_cache_ttl":
      return `/client/config/cache_ttl/${clientId}`;
    case "update_external_points_config":
      return `/clients/${clientId}/features/external_points`;
    case "engagement_coefficients_config":
      return `/clients/${clientId}/loyalty/engagement`;
    case "client_config_football_settings":
      return `/client/config/football_api/${clientId}`;
    case "client_signed_in_url":
      return `/clients/${clientId}/generate-signed-url`;
    case "badges":
      return `/clients/${clientId}/features/loyalty/badges`;
  }
};

export default class ClientHttps extends Https {
  readonly clientId: string = "";

  constructor(clientId: string, infrastructure: InfrastructureType) {
    super(infrastructure);
    this.axiosInstance.defaults.baseURL = initBaseURL(infrastructure);
    this.clientId = clientId;
  }

  public getListOfClientsForSpecifiedUser = async (userId: string) => {
    const pathParams = constructPathParams("staff_clients", userId);
    try {
      const data = await this.axiosInstance.get(pathParams);

      return data.data.data;
    } catch (error) {
      console.warn(`There was a problem fetching staff's clients`, error);
      throw error;
    }
  };

  public getRoles = async (): Promise<RolesPermissions> => {
    const pathParams = constructPathParams("roles");
    try {
      const data = await this.axiosInstance.get(pathParams);

      return data.data.data as RolesPermissions;
    } catch (error) {
      console.warn(
        `There was a problem fetching Fans United client roles`,
        error
      );
      throw error;
    }
  };

  public updateStaffInfo = async (
    userId: string,
    body: { [key: string]: string | string[] }
  ): Promise<ProfileInfo> => {
    const pathParams = constructPathParams("staff_info", userId, this.clientId);

    try {
      const { data } = await this.axiosInstance.patch(
        pathParams,
        JSON.stringify(body)
      );

      return remapKeysToSpecificCase(data.data, "snake") as ProfileInfo;
    } catch (error) {
      console.warn(`There was a problem updating staff's information`, error);
      throw error;
    }
  };

  public getStaffMembersForClient = async (
    disableCache: number
  ): Promise<ProfileInfo[]> => {
    const pathParams = constructPathParams(
      "staff_members_client",
      "",
      this.clientId
    );

    try {
      const { data } = await this.axiosInstance.get(pathParams, {
        params: { disable_cache: disableCache },
      });

      // Delete disable_cache query param
      this.deleteAdditionalQueryParams();
      return remapKeysToSpecificCase(data.data, "camel") as ProfileInfo[];
    } catch (error) {
      console.warn(
        `There was a problem fetching staff members for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public deleteStaffMember = async (userId: string) => {
    const pathParams = constructPathParams("staff_info", userId, this.clientId);

    try {
      await this.axiosInstance.delete(pathParams);
    } catch (error) {
      console.warn(
        `There was a problem deleting staff member with id ${userId} for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getStaffMemberById = async (staffId: string) => {
    const pathParams = constructPathParams(
      "staff_info",
      staffId,
      this.clientId
    );

    try {
      const { data } = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(data.data, "camel") as FullProfileInfo;
    } catch (error) {
      console.warn(
        `There was a problem fetching staff member's information with id ${staffId} for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public createStaffMember = async (staffMember: StaffMemberRequestBody) => {
    const pathParams = constructPathParams(
      "staff_members_client",
      "",
      this.clientId
    );
    // Create a new reference of staff member. That way we avoid deleting the repeatPass field from the original staff member reference.
    const copyStaffMember = JSON.parse(JSON.stringify(staffMember));

    try {
      delete copyStaffMember.repeatPass;
      await this.axiosInstance.post(pathParams, JSON.stringify(staffMember));
    } catch (error) {
      console.warn(
        `There was a problem with creating a staff member for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public updateFeaturesForClient = async (
    body: any,
    featureType: FansUnitedFeatures
  ) => {
    const pathParams = constructPathParams(
      "specific_feature",
      "",
      this.clientId,
      featureType
    );

    try {
      await this.axiosInstance.patch(pathParams, JSON.stringify(body));
    } catch (error) {
      console.warn(
        `There was a problem updating features for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClientFeatures = async () => {
    const pathParams = constructPathParams(
      "client_features",
      "",
      this.clientId
    );

    try {
      const response = await this.axiosInstance.get(pathParams, {
        params: { disable_cache: Date.now() },
      });
      this.deleteAdditionalQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching features information for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClientSpecificFeature = async (feature: FansUnitedFeatures) => {
    const pathParams = constructPathParams(
      "specific_feature",
      "",
      this.clientId,
      feature
    );

    try {
      const response = await this.axiosInstance.get(pathParams, {
        params: { disable_cache: Date.now() },
      });
      this.deleteAdditionalQueryParams();

      return remapKeysToSpecificCase(response.data.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching specific feature information for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getBadges = async () => {
    const pathParams = constructPathParams("badges", "", this.clientId);

    try {
      const response = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(response.data, "camel");
    } catch (error) {
      console.warn(
        `There was a problem fetching badges for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClientCacheTtlConfig = async (apiId: string, endpoint: string) => {
    const pathParams = constructPathParams(
      "client_config_cache_ttl",
      "",
      this.clientId
    );

    try {
      const response = await this.axiosInstance.get(pathParams, {
        params: { api_id: apiId, endpoint },
      });
      this.deleteAdditionalQueryParams();

      return remapKeysToSpecificCase(
        response.data.data,
        "camel"
      ) as CacheConfigModel;
    } catch (error) {
      console.warn(
        `There was a problem fetching cache ttl configuration for API's '${apiId}' endpoint '${endpoint}' for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public updateClientCacheTtlConfig = async (requestBody: {
    [key: string]: any;
  }) => {
    const pathParams = constructPathParams(
      "client_config_cache_ttl",
      "",
      this.clientId
    );

    try {
      await this.axiosInstance.patch(pathParams, requestBody);
    } catch (error) {
      console.warn(
        `There was a problem updating cache ttl configuration for API's '${requestBody.api_id}' endpoint '${requestBody.endpoint}' for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public updateExternalPointsConfig = async (requestBody: {
    [key: string]: any;
  }) => {
    const pathParams = constructPathParams(
      "update_external_points_config",
      "",
      this.clientId
    );

    try {
      await this.axiosInstance.patch(pathParams, requestBody);
    } catch (error) {
      console.warn(
        `There was a problem updating external points configuration for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getClientInfo = async () => {
    const pathParams = constructPathParams("client_info", "", this.clientId);

    try {
      const response = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(
        response.data.data,
        "camel"
      ) as ClientFullInfo;
    } catch (error) {
      console.warn(
        `There was a problem getting information for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getEngagementCoefficientsConfig = async () => {
    const pathParams = constructPathParams(
      "engagement_coefficients_config",
      "",
      this.clientId
    );

    try {
      const response = await this.axiosInstance.get(pathParams);

      return remapKeysToSpecificCase(
        response.data.data,
        "camel"
      ) as EngagementCoefficientsConfiguration;
    } catch (error) {
      console.warn(
        `There was a problem fetching engagement coefficients configuration for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  public getFootballSettingsConfig = async () => {
    const pathParams = constructPathParams(
      "client_config_football_settings",
      "",
      this.clientId
    );

    try {
      const response = await this.axiosInstance.get(pathParams);
      const model = new FootballSettingsConfigModel();

      model.disableAvailableAssets =
        response.data.data.disable_available_assets;

      if (response.data.data.default_assets) {
        model.defaultAssets.competitionLogo =
          response.data.data.default_assets.competition_logo;
        model.defaultAssets.teamLogo =
          response.data.data.default_assets.team_logo;
        model.defaultAssets.playerHeadshot =
          response.data.data.default_assets.player_headshot;
      }

      if (response.data.data.custom_assets) {
        model.customAssets.competitionLogos =
          response.data.data.custom_assets.competition_logos;
        model.customAssets.teamLogos =
          response.data.data.custom_assets.team_logos;
        model.customAssets.playerHeadshots =
          response.data.data.custom_assets.player_headshots;
      }

      return model;
    } catch (error) {
      console.warn(
        `There was a problem fetching football settings configuration for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public updateFootballSettingsConfig = async (requestBody: any) => {
    const pathParams = constructPathParams(
      "client_config_football_settings",
      "",
      this.clientId
    );

    try {
      await this.axiosInstance.put(pathParams, JSON.stringify(requestBody));
    } catch (error) {
      console.warn(
        `There was a problem updating football settings configuration for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public updateEngagementCoefficientsConfig = async (requestBody: any) => {
    const pathParams = constructPathParams(
      "engagement_coefficients_config",
      "",
      this.clientId
    );

    try {
      await this.axiosInstance.put(pathParams, JSON.stringify(requestBody));
    } catch (error) {
      console.warn(
        `There was a problem updating engagement coefficients configuration for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public generateSignedInUrl = async (requestBody: SignedInUrlRequestBody) => {
    const pathParams = constructPathParams(
      "client_signed_in_url",
      "",
      this.clientId
    );

    try {
      return await this.axiosInstance.post(
        pathParams,
        JSON.stringify(requestBody)
      );
    } catch (error) {
      console.warn(
        `There was a problem generating signed in URL for client '${this.clientId}' `,
        error
      );
      throw error;
    }
  };

  public storeInBucket = async (
    signedInUrl: string,
    file: File,
    headers: Record<string, string>
  ) => {
    const response = await fetch(signedInUrl, {
      headers,
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      return this.constructAssetsUrl(file.name);
    }

    this.throwErrorFromXmlResponse(response);
  };

  public updateBadgesForClient = async (body: any) => {
    const pathParams = constructPathParams("badges", "", this.clientId);

    try {
      await this.axiosInstance.put(pathParams, JSON.stringify(body));
    } catch (error) {
      console.warn(
        `There was a problem updating badges for client: ${this.clientId}`,
        error
      );
      throw error;
    }
  };

  private constructAssetsUrl = (fileName: string) => {
    const environment =
      process.env.REACT_APP_MANAGEMENT_PORTAL_ENVIRONMENT || "prod";

    if (environment === "dev") {
      return `https://storage.googleapis.com/client_images_cms_fans-united-dev/${this.clientId}/${fileName}`;
    }

    return `https://uploads.fansunitedassets.com/${this.clientId}/${fileName}`;
  };

  private throwErrorFromXmlResponse = async (response: Response) => {
    const string = await response.text();
    const document = new window.DOMParser().parseFromString(string, "text/xml");
    const message =
      document.getElementsByTagName("Message") &&
      document.getElementsByTagName("Code").length > 0
        ? document.getElementsByTagName("Message")[0].textContent
        : "";
    const details =
      document.getElementsByTagName("Details") &&
      document.getElementsByTagName("Code").length > 0
        ? document.getElementsByTagName("Details")[0].textContent
        : "";

    throw new Error(message + " " + details);
  };

  /**
   * For some requests to Client API we use additional query params.
   * When request is fullfilled we need to delete these query param from axios instance.
   */
  private deleteAdditionalQueryParams = () => {
    if (this.axiosInstance.defaults.params) {
      const axiosQueryParams = JSON.parse(
        JSON.stringify(this.axiosInstance.defaults.params)
      );

      if (axiosQueryParams && axiosQueryParams.disable_cache)
        delete axiosQueryParams.disable_cache;

      if (axiosQueryParams && axiosQueryParams.api_id)
        delete axiosQueryParams.api_id;

      if (axiosQueryParams && axiosQueryParams.endpoint)
        delete axiosQueryParams.endpoint;

      this.axiosInstance.defaults.params = axiosQueryParams;
    }
  };
}
