import React, { useRef } from "react";
import Spinner from "../../../Spinner/Spinner";
import FeaturesLayout from "../FeaturesLayout";
import FeaturesPotm from "./FeaturesPotm";
import { usePotmFeatures } from "../../../../hooks/features/usePotmFeatures";

const FeaturesPotmPage: React.FC = () => {
  // Use to trigger usePotmFeatures custom hook (call new updated features) when features are updated.
  const isFeaturesEdited = useRef<boolean>(false);

  const feature = usePotmFeatures(isFeaturesEdited);

  return (
    <FeaturesLayout
      title={"Configuration for Player of The Mattch"}
      description={"This is Player of The Match configuration for client: "}
    >
      {feature ? (
        <FeaturesPotm feature={feature} isFeaturesEdited={isFeaturesEdited} />
      ) : (
        <Spinner />
      )}
    </FeaturesLayout>
  );
};

export default FeaturesPotmPage;
