import dayjs from "dayjs";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export const setDefaultDatePeriod = () => {
  const apiDateFormat = "YYYY-MM-DD";
  const fromDate = dayjs().subtract(30, "day").format(apiDateFormat);
  const toDate = dayjs().subtract(1, "day").format(apiDateFormat);

  return { fromDate, toDate };
};

interface ReportParamsInterface {
  datePeriod: { fromDate: string; toDate: string };
  comparedToPeriod: { fromDate: string; toDate: string };
  comparedTo: boolean;
  setComparedTo: Dispatch<SetStateAction<boolean>>;
  groupBy: string;
  setGroupBy: Dispatch<SetStateAction<string>>;
  onChangePeriod: (date: Date[], scenario: "period" | "compared_to") => void;
}

export const ReportParamsContext = createContext<ReportParamsInterface>(null);

const ReportParamsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [datePeriod, setDatePeriod] = useState<{
    fromDate: string;
    toDate: string;
  }>(setDefaultDatePeriod());
  const [comparedTo, setComparedTo] = useState<boolean>(false);
  const [comparedToPeriod, setComparedToPeriod] = useState<{
    fromDate: string;
    toDate: string;
  }>(null);
  const [groupBy, setGroupBy] = useState<string>("week");

  const onChangePeriod = (date: Date[], scenario: "period" | "compared_to") => {
    // Trigger onChange event only when the whole period is choosen.
    if (date.length === 2) {
      const apiDateFormat = "YYYY-MM-DD";
      const fromDate = dayjs(date[0]).format(apiDateFormat);
      const toDate = dayjs(date[1]).format(apiDateFormat);

      if (scenario === "period") {
        setDatePeriod({ fromDate, toDate });
      } else {
        setComparedToPeriod({ fromDate, toDate });
      }
    } else if (date.length === 0) {
      // When clearing period input
      if (scenario === "period") {
        setDatePeriod(null);
      } else {
        setComparedToPeriod(null);
      }
    }
  };

  return (
    <ReportParamsContext.Provider
      value={{
        datePeriod,
        comparedToPeriod,
        comparedTo,
        groupBy,
        onChangePeriod,
        setGroupBy,
        setComparedTo,
      }}
    >
      {children}
    </ReportParamsContext.Provider>
  );
};

export default ReportParamsProvider;
