import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../../../enums/enums";
import { extractEntityIds } from "../../../../../../helpers/helpers";
import SuccessRateScopesRequestBody from "../../../../../../models/Clients/featuresRequestBodies/predictor/SuccessRateScopesRequestBody";
import PredictorSuccessRateScopesModel from "../../../../../../models/features/predictor/PredictorSuccessRateScopesModel";
import { GlobalStatesContext } from "../../../../../../providers/GlobalStatesProvider";
import UpdateButton from "../../../../../Buttons/UpdateButton";
import SuccessRateAllMarketsList from "./Markets/SuccessRateAllMarketsList";
import SuccessRateCompetitionSelect from "./SuccessRateCompetitionSelect/SuccessRateCompetitionSelect";
import TeamsSelect from "./TeamsSelect/TeamsSelect";
import { ApiContext } from "../../../../../../providers/ApiProvider";

type EditSuccessRateScopesProps = {
  successRateScopes: PredictorSuccessRateScopesModel;
  allMarkets: string[];
  isSuccessRateEdited: any;
};

const EditSuccessRateScopes: React.FC<EditSuccessRateScopesProps> = ({
  successRateScopes,
  allMarkets,
  isSuccessRateEdited,
}) => {
  const [successRateScopeCompetitionIds, setSuccessRateScopeCompetitionIds] =
    useState<string[]>(extractEntityIds(successRateScopes.competitions));
  const [successRateScopeTeamIds, setSuccessRateScopeTeamIds] = useState<
    string[]
  >(extractEntityIds(successRateScopes.teams));
  const [successRateScopeMarkets, setSuccessRateScopeMarkets] = useState<
    string[]
  >(successRateScopes.markets);
  const history = useHistory();

  const { selectedClient, isLoading, setIsLoading } =
    useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  useEffect(() => {}, [successRateScopeMarkets]);

  const backToFeaturesPage = () => history.goBack();

  const constructRequestBody = () => {
    const requestBody = new SuccessRateScopesRequestBody();

    requestBody.success_rate_scopes.competitions =
      successRateScopeCompetitionIds;
    requestBody.success_rate_scopes.teams = successRateScopeTeamIds;
    requestBody.success_rate_scopes.markets = successRateScopeMarkets;

    return requestBody;
  };

  const editSuccessRateScopes = async () => {
    setIsLoading(true);
    const successRateScopesRequestBody = constructRequestBody();

    try {
      await clientHttps.updateFeaturesForClient(
        successRateScopesRequestBody,
        FansUnitedFeatures.PREDICTOR
      );
      isSuccessRateEdited.current = !isSuccessRateEdited.current;
      setIsLoading(false);
      backToFeaturesPage();
      toast.success(SUCCESS_MESSAGES.FEATURES_PREDICTOR_UPDATE);
    } catch (error) {
      console.error(error);
      toast.error(ERROR_MESSAGES.FEATURES_PREDICTOR_UPDATE);
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor={"Competitions"}
        >
          Competitions
        </label>
        <SuccessRateCompetitionSelect
          loadedCompetitions={successRateScopes?.competitions}
          setCompetitionIds={setSuccessRateScopeCompetitionIds}
        />
      </div>
      <div className="w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor={"Competitions"}
        >
          Teams
        </label>
        <TeamsSelect
          loadedTeams={successRateScopes?.teams}
          setSuccessRateScopeTeamIds={setSuccessRateScopeTeamIds}
        />
      </div>
      <div className="w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor={"Markets"}
        >
          Markets
        </label>
        <SuccessRateAllMarketsList
          allMarkets={allMarkets}
          loadedMarkets={successRateScopeMarkets}
          setMarkets={setSuccessRateScopeMarkets}
        />
      </div>
      <UpdateButton
        label={"Update"}
        onClick={editSuccessRateScopes}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EditSuccessRateScopes;
