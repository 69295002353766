import { useContext, useState } from "react";
import Spinner from "../../../Spinner/Spinner";
import Enabled from "../Common/Enabled";
import UpdateButton from "../../../Buttons/UpdateButton";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGES,
  FansUnitedFeatures,
  SUCCESS_MESSAGES,
} from "../../../../enums/enums";
import { ApiContext } from "../../../../providers/ApiProvider";
import PotmFeatureModel from "../../../../models/features/potm/PotmFeatureModel";
import { inputNumber } from "../../../../helpers/helpers";

type FeaturesPotmProps = {
  feature: PotmFeatureModel;
  isFeaturesEdited: { current: boolean };
};

const FeaturesPotm: React.FC<FeaturesPotmProps> = ({
  feature,
  isFeaturesEdited,
}) => {
  const [votingWindow, setVotingWindow] = useState<number>(
    feature.votingWindow || 24
  );

  const { isLoading, setIsLoading } = useContext(GlobalStatesContext);
  const { clientHttps } = useContext(ApiContext);

  const onChangeVotingWindow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVotingWindow(Number(event.target.value));
  };

  const updateFeature = async () => {
    setIsLoading(true);
    const requestBody = {
      voting_window: votingWindow,
    };

    try {
      await clientHttps.updateFeaturesForClient(
        requestBody,
        FansUnitedFeatures.POTM
      );
      toast.success(SUCCESS_MESSAGES.FEATURES_POTM_UPDATE);
      isFeaturesEdited.current = !isFeaturesEdited.current;
    } catch (e) {
      console.error(e);
      toast.error(ERROR_MESSAGES.POTM_FEATURE_UPDATE);
    }

    setIsLoading(false);
  };

  return (
    <>
      {feature ? (
        <>
          <Enabled value={feature.enabled} />
          <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
            <label className="font-bold mb-2">Voting window</label>
            <p className="mb-2">
              Determines how long users can submit their Player of The Match
              votes after a football match ends. You can set this duration
              between 1 and 168 hours (1 week). Once the match is finished, the
              voting period will remain open for the specified number of hours.
            </p>
            <input
              className="h-16 w-20 text-2xl text-brandColor bg-blueGray-100 border border-bluGray-200 rounded mt-auto text-center font-bold my-2"
              type="number"
              name="potm_voting_window"
              value={votingWindow}
              onChange={onChangeVotingWindow}
              onKeyDown={(event) => inputNumber(event, false)}
            ></input>
          </div>
          <UpdateButton
            label={"Update"}
            onClick={updateFeature}
            isLoading={isLoading}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default FeaturesPotm;
