import React from "react";

type ReportInfoProps = {
  title: string;
  description: string;
};

const ReportInfo: React.FC<ReportInfoProps> = ({ title, description }) => {
  return (
    <div className="mb-4">
      <h2 className="font-bold mb-2 md:mb-1">{title}</h2>
      <p
        className="text-sm"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default ReportInfo;
