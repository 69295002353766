import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { Dispatch, SetStateAction, useState } from "react";
import { asyncSelectStyles } from "../../../../../constants/styles";
import SelectOption from "../../../../../models/SelectOption/SelectOption";
import Select from "react-select";
import { placeholders } from "../../../../../assets/placeholders/placeholders";
import _ from "lodash";
import { formatOptions } from "../../../../../helpers/helpers";

type CompetitionsSelectProps = {
  loadedCompetitions: CompetitionBasicModel[];
  unselectedCompetitions: CompetitionBasicModel[];
  setCompetitionIds: Dispatch<SetStateAction<string[]>>;
  setDisabledButton: Dispatch<SetStateAction<boolean>>;
};

const CompetitionsSelect: React.FC<CompetitionsSelectProps> = ({
  loadedCompetitions,
  unselectedCompetitions,
  setCompetitionIds,
  setDisabledButton,
}) => {
  const loadedCompetitionOptions = loadedCompetitions?.map(
    (competition: CompetitionBasicModel) => formatOptions(competition)
  );
  const options = unselectedCompetitions.map(
    (competition: CompetitionBasicModel) => formatOptions(competition)
  );
  const [competitions, setCompetitions] = useState<SelectOption[]>(
    loadedCompetitionOptions
  );

  const handleOnChange = (selectedOptions: SelectOption[]) => {
    const competitionIds = selectedOptions.map(
      (selectedOption: SelectOption) => selectedOption.id
    );
    setCompetitionIds(competitionIds);
    setCompetitions(selectedOptions);
  };

  const noOptionsMessage = () => {
    return !options.length ? "No competitions left to add" : null;
  };

  return (
    <Select
      components={{ DropdownIndicator: null }}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
      styles={asyncSelectStyles}
      onMenuOpen={() => setDisabledButton(true)}
      onMenuClose={() => setDisabledButton(false)}
      isMulti
      isClearable
      onChange={handleOnChange}
      defaultValue={competitions}
      options={options}
      placeholder={""}
      formatOptionLabel={(option) => (
        <div>
          <div className="flex flex-justify-start mb-2">
            <img
              src={option.logo ? option.logo : placeholders.competition}
              alt={""}
              className="h-5 w-5"
            />
            <span>{option.label}</span>
          </div>
          <div className="flex flex-justify-start">
            <img src={option.countryFlag} alt={""} className="h-5 w-5 mr-1" />
            <span>{option.countryName}</span>
          </div>
        </div>
      )}
    />
  );
};

export default CompetitionsSelect;
