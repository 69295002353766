import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../providers/ApiProvider";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { ERROR_MESSAGES, FansUnitedFeatures } from "../../enums/enums";
import { toast } from "react-toastify";
import PotmFeatureModel from "../../models/features/potm/PotmFeatureModel";

export const usePotmFeatures = (isFeaturesEdited: any) => {
  const [potmFeature, setPotmFeature] = useState<PotmFeatureModel>(null);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const getPotmClientFeature = async () => {
    try {
      const feature = await clientHttps.getClientSpecificFeature(
        FansUnitedFeatures.POTM
      );
      setPotmFeature(feature);
    } catch (e) {
      toast.error(ERROR_MESSAGES.POTM_FEATURE);
      setPotmFeature(new PotmFeatureModel());
    }
  };

  useEffect(() => {
    getPotmClientFeature();

    return () => setPotmFeature(null);
  }, [selectedClient, isFeaturesEdited.current]);

  return potmFeature;
};
