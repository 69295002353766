import CompetitionBasicModel from "fansunited-sdk-esm/Core/Namespaces/Football/Models/Competition/CompetitionBasicModel";
import React, { Dispatch, SetStateAction } from "react";
import CompetitionsSelect from "../../Common/CompetitionsSelect/CompetitionsSelect";

type MatchQuizCompetitionsWhitelistProps = {
  title: string;
  description: string;
  loadedCompetitions: CompetitionBasicModel[];
  unselectedCompetitions: CompetitionBasicModel[];
  setCompetitionIds: Dispatch<SetStateAction<string[]>>;
  setDisabledButton: Dispatch<SetStateAction<boolean>>;
};

const MatchQuizCompetitionsWhitelist: React.FC<
  MatchQuizCompetitionsWhitelistProps
> = ({
  title,
  description,
  loadedCompetitions,
  unselectedCompetitions,
  setCompetitionIds,
  setDisabledButton,
}) => {
  return (
    <div className="mb-2 border border-gray-200 rounded-lg p-2 bg-blueGray-50">
      <label className="font-bold mb-2">{title}</label>
      <p className="mb-2">{description}</p>
      <CompetitionsSelect
        loadedCompetitions={loadedCompetitions}
        unselectedCompetitions={unselectedCompetitions}
        setCompetitionIds={setCompetitionIds}
        setDisabledButton={setDisabledButton}
      />
    </div>
  );
};

export default MatchQuizCompetitionsWhitelist;
