import { createPopper } from "@popperjs/core";
import React, { useRef, useState } from "react";
import { eventsMap } from "./Events/WebhookEventsComponent";

const eventsDescriptionMap = new Map<string, string>()
  .set(
    "profile",
    "A new profile being created, an existing one being updated or deleted and when users are updating their interests."
  )
  .set(
    "profile_activity",
    "Earning a new tier or badge, getting a new follower and other activities such as like, page view, share and etc."
  )
  .set(
    "football_matches",
    "When a match has been resolved one way or another. Depending on the match outcome (resolved, interrupted, abandoned, canceled and deleted)."
  )
  .set(
    "predictions",
    "User places a new prediction, updates an existing one or deletes it."
  )
  .set(
    "match_quiz",
    "Тhe game is created or updated, when the status has changed or the winners were announced. Also user prediction for specific Match Quiz game."
  )
  .set(
    "top_x",
    "Тhe game is created or updated, when the status has changed or the winners were announced. Also user prediction for specific Top X game."
  )
  .set(
    "leaderboard_templates",
    "А new template has been created, existing one has been updated or deleted and when the winners for a particular template have been announced."
  )
  .set(
    "private_league",
    "A new private league has been created, an existing one has been updated or deleted. Also all actions related to users - users are added, removed, invited, joined, left and banned."
  )
  .set(
    "one_vs_one",
    "A new challenge (1 vs 1) has been created, an existing one has been updated or deleted. Also all actions related to users - users are added, removed, invited, joined, left and banned."
  )
  .set(
    "discussion",
    "A post in discussions is created/updated/deleted by user. Also all actions related to posts such as - making a reaction to post, updating a reaction, deleting reaction or moderating post by staff member."
  )
  .set(
    "classic_quiz",
    "A new Classic Quiz mini game is created, update or deleted. A new user mini game participation has been made in specific. Classic Quiz."
  )
  .set(
    "either_or",
    "A new Either/Or mini game is created, update or deleted. A new user mini game participation has been made in specific Either/Or."
  )
  .set(
    "football_player_of_the_match",
    "A new user vote is made for the player of the match in a specific football match."
  )
  .set(
    "poll",
    "A new Poll is created, update or deleted. A new user participation has been made in specific Poll."
  )
  .set(
    "lead",
    "A new user lead activity is created."
  )
  .set(
    "scheduled_task",
    "The internal scheduled task is created, updated, deleted or has been executed (changed status to concrete Fans Untied entity such as Top X Game, Classic Quiz and etc.)."
  );

const httpTitleMap = new Map<string, string>()
  .set("url", "URL")
  .set("method", "Method")
  .set("name", "Username")
  .set("password", "Password");

const httpDescriptionMap = new Map<string, string>()
  .set(
    "url",
    "To make the request, webhook uses a URL, which includes the information needed to access the resource."
  )
  .set(
    "method",
    "Define request method to indicate the desired action to be performed for a given resource."
  )
  .set("name", "Username to be used in Authorization header.")
  .set("password", "Password to be used in Authorization header.");

const pubSubTitleMap = new Map<string, string>()
  .set("projectId", "Project ID")
  .set("topicName", "Topic Name")
  .set("json", "Credentials JSON");

const pubSubDescriptionMap = new Map<string, string>()
  .set(
    "projectId",
    "Your Google Project ID. To locate your project ID you need to visit your Google API console and from the projects list select Manage all projects."
  )
  .set("topicName", "The name you gave to your Pub/Sub topic.")
  .set(
    "json",
    "Contains the credentials and data for connecting to Pub/Sub with a service account key."
  );

const kafkaTitleMap = new Map<string, string>()
  .set("url", "URL")
  .set("method", "Method")
  .set("name", "Name")
  .set("password", "Password")
  .set("topic", "Topic")
  .set("securityProtocol", "Security Protocol")
  .set("saslMechanism", "SASL Mechanism")
  .set("loginModule", "Login Module")
  .set("requestRequiredAcks", "Request Required Acks")
  .set("additionalProperties", "Additional Properties");

const kafkaDescriptionMap = new Map<string, string>()
  .set(
    "url",
    "A list of host/port pairs to use for establishing the initial connection to the Kafka cluster."
  )
  .set(
    "method",
    "Sets the methods supported for cross origin requests by setting the Access-Control-Allow-Methods header. The default value of the Access-Control-Allow-Methods header allows cross origin requests for GET and POST."
  )
  .set("name", "Username to be used in the JAAS Config.")
  .set("password", "Password to be used in the JAAS Config.")
  .set(
    "topic",
    "Kafka topics are the categories used to organize messages. Each topic has a name that is unique across the entire Kafka cluster. The topic name can be up to 255 characters in length, and can include the following characters: a-z, A-Z, 0-9, . (dot), _ (underscore), and - (dash)."
  )
  .set(
    "securityProtocol",
    "Protocol used to communicate with brokers. Valid values are: PLAINTEXT, SSL, SASL_PLAINTEXT, SASL_SSL."
  )
  .set(
    "saslMechanism",
    "SASL mechanism used for client connections. This may be any mechanism for which a security provider is available. GSSAPI is the default mechanism."
  )
  .set(
    "loginModule",
    "JAAS login context parameters for SASL connections in the format used by JAAS configuration files."
  )
  .set(
    "requestRequiredAcks",
    "This value controls when a produce request is considered completed. Typical values are: 0, which means that the producer never waits for an acknowledgement from the broker (the same behavior as 0.7). This option provides the lowest latency but the weakest durability guarantees (some data will be lost when a server fails). 1, which means that the producer gets an acknowledgement after the leader replica has received the data. This option provides better durability as the client waits until the server acknowledges the request as successful (only messages that were written to the now-dead leader but not yet replicated will be lost). -1, which means that the producer gets an acknowledgement after all in-sync replicas have received the data. This option provides the best durability, we guarantee that no messages will be lost as long as at least one in sync replica remains."
  )
  .set(
    "additionalProperties",
    "Sets additional properties for either kafka consumer or kafka producer in case they can’t be set directly on the camel configurations (e.g: new Kafka properties that are not reflected yet in Camel configurations), the properties have to be prefixed with additionalProperties.. E.g: additionalProperties.transactional.id=12345&additionalProperties.schema.registry.url=http://localhost:8811/avro."
  );

const tooltipTitle = (
  tooltipType: "http" | "pub_sub" | "kafka" | "events",
  property: string
) => {
  switch (tooltipType) {
    case "events":
      return eventsMap.get(property);
    case "http":
      return httpTitleMap.get(property);
    case "pub_sub":
      return pubSubTitleMap.get(property);
    case "kafka":
      return kafkaTitleMap.get(property);
  }
};

const tooltipDescription = (
  tooltipType: "http" | "pub_sub" | "kafka" | "events",
  property: string
) => {
  switch (tooltipType) {
    case "events":
      return eventsDescriptionMap.get(property);
    case "http":
      return httpDescriptionMap.get(property);
    case "pub_sub":
      return pubSubDescriptionMap.get(property);
    case "kafka":
      return kafkaDescriptionMap.get(property);
  }
};

const toolTipRequestRequiredAcksDescription = () => {
  return (
    <>
      <p className="mb-2">
        This value controls when a produce request is considered completed.
      </p>
      <ul>
        <p className="mb-2">Typical values are:</p>
        <li className="mb-2">
          <strong>0</strong>, which means that the producer never waits for an
          acknowledgement from the broker (the same behavior as 0.7). This
          option provides the lowest latency but the weakest durability
          guarantees (some data will be lost when a server fails).
        </li>
        <li className="mb-2">
          <strong>1</strong>, which means that the producer gets an
          acknowledgement after the leader replica has received the data. This
          option provides better durability as the client waits until the server
          acknowledges the request as successful (only messages that were
          written to the now-dead leader but not yet replicated will be lost).
        </li>
        <li>
          <strong>-1</strong>, which means that the producer gets an
          acknowledgement after all in-sync replicas have received the data.
          This option provides the best durability, we guarantee that no
          messages will be lost as long as at least one in sync replica remains.
        </li>
      </ul>
    </>
  );
};

type WebhookTooltipProps = {
  tooltipType: "http" | "pub_sub" | "kafka" | "events";
  property: string;
};

const WebhookTooltip: React.FC<WebhookTooltipProps> = ({
  property,
  tooltipType,
}) => {
  const [popoverShow, setPopoverShow] = useState<boolean>(false);

  const iconRef = useRef<SVGSVGElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const openTooltip = () => {
    createPopper(
      iconRef.current as SVGSVGElement,
      popoverRef.current as HTMLDivElement,
      {
        placement: "right",
      }
    );
    setPopoverShow(true);
  };

  const closeTooltip = () => {
    setPopoverShow(false);
  };

  return (
    <div className={tooltipType === "events" ? "ml-auto" : ""}>
      <div className="flex flex-wrap">
        <div className="w-full text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
            onMouseEnter={openTooltip}
            onMouseLeave={closeTooltip}
            ref={iconRef}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
          <div
            className={`${popoverShow ? "" : "hidden"}
              bg-blueGray-600 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg`}
            ref={popoverRef}
          >
            <div>
              <div className="bg-blueGray-600 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg">
                {tooltipTitle(tooltipType, property)}
              </div>
              <div className="text-white p-3">
                {property === "requestRequiredAcks"
                  ? toolTipRequestRequiredAcksDescription()
                  : tooltipDescription(tooltipType, property)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhookTooltip;
