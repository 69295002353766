import React, { useState } from "react";
import CardReport from "../../../Cards/CardReport";
import ReportInfo from "../Common/ReportInfo";
import DatePeriod from "../Common/DatePeriod";
import GroupByPeriod from "../Common/GroupByPeriod";
import { usePotmBreakdown } from "../../../../hooks/reports/potm/usePotmBreakdown";
import { usersBreakdownDefault } from "../../../../constants/constants";
import UsersBreakdownBuilder from "../../../../builders/reports/UsersBreakdownBuilder";
import Spinner from "../../../Spinner/Spinner";
import LineChart from "../Common/LineChart";
import TotalUsers from "../Common/TotalUsers/TotalUsers";
import {
  constructLabelsChart,
  formatOptions,
} from "../../../../helpers/helpers";
import { CompetitionBasicModel } from "fansunited-sdk-esm";
import SelectOption from "../../../../models/SelectOption/SelectOption";
import Select from "react-select";
import { asyncSelectStyles } from "../../../../constants/styles";
import { imageLogoEntity } from "../Common/EntityFollows/EntitySelect";

const PlayerOfTheMatchReport: React.FC = () => {
  const [competitionId, setCompetitionId] = useState<string>("");
  const [usersBreakdown, setUsersBreakdown] = useState<string[]>(
    usersBreakdownDefault
  );

  const { potmBreakdown, fullCoverageCompetitions } =
    usePotmBreakdown(competitionId);
  const datasets = new UsersBreakdownBuilder(
    JSON.parse(JSON.stringify(potmBreakdown)),
    usersBreakdown
  ).build();

  const onChangeCheckbox = (userBreakdown: string) => {
    const currentUsersBreakdown = [...usersBreakdown];

    if (
      currentUsersBreakdown.includes(userBreakdown) &&
      currentUsersBreakdown.length !== 1
    ) {
      const newUsersBreakdown = currentUsersBreakdown.filter(
        (value: string) => value !== userBreakdown
      );
      setUsersBreakdown(newUsersBreakdown);
    } else if (!currentUsersBreakdown.includes(userBreakdown)) {
      currentUsersBreakdown.push(userBreakdown);
      setUsersBreakdown(currentUsersBreakdown);
    }
  };

  const loadedEntities = fullCoverageCompetitions?.map(
    (entity: CompetitionBasicModel) => formatOptions(entity)
  );

  const handleOnChange = (selectedOption: SelectOption) => {
    const competitionId = selectedOption ? selectedOption.id : "";
    setCompetitionId(competitionId);
  };

  return (
    <div className="w-full px-4">
      <CardReport>
        <div className="headingContainer mb-6">
          <ReportInfo
            title={"Player of The Match total votes"}
            description={
              "This is a breakdown by period of users votes for Player of The Match. You can filter out the data by specific competition which is selected from your full coverage ones <strong>(Features -> Predictor -> Full coverage competitions).</strong> In this report you will also find the total votes and voted users percentage."
            }
          />
          <div className="xl:flex xl:flex-row xl:items-center">
            <DatePeriod />
          </div>
        </div>
        <GroupByPeriod />
        {/** Full coverage competition select */}
        <div className="mb-6">
          <Select
            getOptionValue={(option) => option.id} // Provides an unique key value to every multi value option
            styles={asyncSelectStyles}
            isClearable
            onChange={handleOnChange}
            options={loadedEntities}
            placeholder={"Filter out by full coverage competition"}
            formatOptionLabel={(option) => (
              <div className="flex items-center">
                {imageLogoEntity("competitions", option)}
                <span className="mr-2">{option.label}</span>
                <img
                  src={option.countryFlag}
                  alt={""}
                  className="h-5 w-5 mr-1"
                />
                <span className="mr-1">{option.countryName}</span>
              </div>
            )}
          />
        </div>
        {potmBreakdown && potmBreakdown.current ? (
          <>
            <TotalUsers
              total={potmBreakdown.current.users.total}
              reportType={"users_acquired"}
              usersBreakdown={usersBreakdown}
              onChangeCheckbox={onChangeCheckbox}
            />
            <LineChart
              labels={constructLabelsChart(
                potmBreakdown.current.users.breakdown,
                potmBreakdown?.comparedTo?.users.breakdown
              )}
              datasets={datasets}
            />
          </>
        ) : (
          <Spinner />
        )}
      </CardReport>
    </div>
  );
};

export default PlayerOfTheMatchReport;
